<template>
  <v-combobox
      v-model="select"
      @change="setOutput"
      :items="items"
      item-text="name"
      return-object
      :loading="loading"
      :search-input.sync="search"
      hide-no-data
      no-filter
      label="Название ЦЗН"
  ></v-combobox>
</template>

<script>
export default {
  name: "EgrulInput",
  props: ["initValue"],
  data: function () {
    return {
      loading: false,
      items: [],
      search: null,
      select: null
    };
  },
  watch: {
    search(val) {
      val && val !== this.select && this.querySelections(val)
    },
  },
  methods: {
    async querySelections(v) {
      this.loading = true
      await this.getEgrul(v)
      this.loading = false
    },


    setOutput(val){
      // alert(val)
      this.$emit('changeNameOfUser', val)
    },

    async getEgrul(str) {
      const headers = new Headers()
      headers.append('Authorization', 'Token e40acab5e85ecae934e0813338306683301f32e3')
      headers.append('Accept', 'application/json')
      headers.append('Content-Type', 'application/json')
      let data = {query: str}
      let req = await fetch('https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party',
          {method: 'POST', headers: headers, body: JSON.stringify(data)})
      let suggestions = (await req.json()).suggestions
      // console.log('suggestions', suggestions)
      this.items = suggestions.map((e)=>{return {name: e.data.name.full, region: {name: e.data.address.data.region}}})
    }
  },
  beforeMount() {
    if(this.initValue != null){
      this.items = [{value: this.initValue}]
      this.select=this.initValue
    }
  }
}
</script>

<style scoped>

</style>
